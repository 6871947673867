import React from 'react';

import HomeSection from './HomeSection';
import Col from '../common/Column';
import Container from '../common/Container';
import Row from '../common/Row';

import cccc from '../../images/clients/LOGO_CCCC.jpeg';
import bci from '../../images/clients/bci.png';
import rockTrust from '../../images/clients/rock_trust.png';
import crg5 from '../../images/clients/crg5.png';
import cic from '../../images/clients/cic.png';
import arc from '../../images/clients/arc.png';
import terrain from '../../images/clients/terrain.png';
import cico from '../../images/clients/cico.jpeg';
import anchor from '../../images/clients/anchor.png';
import arab from '../../images/clients/arab.png';
import csgec from '../../images/clients/csgec.png';
import strabag from '../../images/clients/strabag.jpeg';
import uwa from '../../images/clients/uwa.jpeg';
import chico from '../../images/clients/chico.png';
import cwyc from '../../images/clients/cwyc.png';

export default function ClientSection() {

  return (
    <HomeSection
      title="Clients"
      subheader="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut."
      className="light-bg"
    >
      <Container id="clients-section">
        <Row className="section-content">
          <Col className="col-lg-12">
            <ul className="brand-list branstyle--2">
              <li>
                <img src={cccc} alt="cccc" />
              </li>
              <li>
                <img src={bci} alt="Beza Consulting Engineers" />
              </li>
              <li>
                <img src={rockTrust} alt="RockTrust Contractors Limited" />
              </li>
              <li>
                <img src={crg5} alt="China Railway Number Fove Engineering Group" />
              </li>
              <li>
                <img src={cic} alt="Center for Infrastructure Consulting Ltd" />
              </li>
              <li>
                <img src={arc} alt="Arch Design" />
              </li>
              <li>
                <img src={terrain} alt="Terrain Services Limited" />
              </li>
              <li>
                <img src={cico} alt="Chongqing International Construction Corporation" />
              </li>
              <li>
                <img src={anchor} alt="Anchor Engineeging Services Limited" />
              </li>
              <li>
                <img src={arab} alt="Arab Contractors" />
              </li>
              <li>
                <img src={csgec} alt="China State Engineering Corporation" />
              </li>
              <li>
                <img src={strabag} alt="Strabag Internation" />
              </li>
              <li>
                <img src={uwa} alt="Uganda Wildlife Authority" />
              </li>
              <li>
                <img src={chico} alt="China Henan International Corporation" />
              </li>
              <li>
                <img src={cwyc} alt="China WuYi" />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </HomeSection>
  );
}
