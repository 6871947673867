import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// redux
import { getServices } from '../../redux/actions/serviceActions';
import STATUS from '../../redux/actions/constants';

// components
import ContainerLoader from '../common/ContainerLoader';
import Messenger from '../common/Messenger';
import Row from '../common/Row';
import Col from '../common/Column';
import Container from '../common/Container';
import HomeSection from './HomeSection';

function ServicesSection() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, []);

  const { status, services } = useSelector((state) => state.services);

  return (
    <HomeSection
      title="Services"
      subheader="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut."
    >
      <Container id="service-section">
        <Row className="section-content">
          <Col className="col-lg-12">
            {status === STATUS.LOADING && (<ContainerLoader />)}
            {status !== STATUS.LOADING && services && services.length === 0 && (
              <Messenger message="Bear With Us As We Populate Our List Of Servives" />
            )}
            {status !== STATUS.LOADING && services && services.length > 0 && (
              <Row>
                {services.map((service) => (
                  <Col key={service.id} className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <Link to={`services/${service.slug}`} className="service-link" href="/">
                      <div className="service">
                        <div className="icon layout horizontal center-justified">
                          <i className={`fa ${service.icon}`} />
                        </div>
                        <div className="content">
                          <h3 className="title dark">{service.name.toLowerCase()}</h3>
                          <p className="big">
                            {service.short_description}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </HomeSection>
  );
}

export default ServicesSection;
